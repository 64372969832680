/* New Fonts for Redesign -  Delete old ones above */
@font-face {
  font-family: 'Idlewild';
  src: url('./assets/fonts/Idlewild/Idlewild-Medium.otf') format("opentype");;
}

@font-face {
  font-family: 'Decimal';
  src: url('./assets/fonts/Decimal/Decimal-Medium-Pro.otf') format("opentype");;
}

/* Helvetica Now Display */
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplay.otf') format("opentype");;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayIt.otf') format("opentype");;
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayHairline.otf') format("opentype");;
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayHairIt.otf') format("opentype");;
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayThin.otf') format("opentype");;
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayThIt.otf') format("opentype");;
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayLight.otf') format("opentype");;
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayLtIt.otf') format("opentype");;
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayMedium.otf') format("opentype");;
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayMdIt.otf') format("opentype");;
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayBold.otf') format("opentype");;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayBdIt.otf') format("opentype");;
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayXBd.otf') format("opentype");;
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayXBdIt.otf') format("opentype");;
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayBlack.otf') format("opentype");;
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayBlkIt.otf') format("opentype");;
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayXBlk.otf') format("opentype");;
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowDisplayXBlkIt.otf') format("opentype");;
  font-weight: 900;
  font-style: italic;
}

/* Helvetica Now Micro */
@font-face {
  font-family: 'HelveticaNowMicro';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowMicro.otf') format("opentype");;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowMicro';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowMicroIt.otf') format("opentype");;
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowMicro';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowMicroExtraLight.otf') format("opentype");;
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowMicro';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowMicroXLtIt.otf') format("opentype");;
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowMicro';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowMicroLight.otf') format("opentype");;
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowMicro';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowMicroLtIt.otf') format("opentype");;
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowMicro';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowMicroMedium.otf') format("opentype");;
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowMicro';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowMicroMdIt.otf') format("opentype");;
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowMicro';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowMicroBold.otf') format("opentype");;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowMicro';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowMicroBdIt.otf') format("opentype");;
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowMicro';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowMicroExtraLight.otf') format("opentype");;
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowMicro';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowMicroXBdIt.otf') format("opentype");;
  font-weight: 700;
  font-style: italic;
}

/* Helvetica Now Text */
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowText.otf') format("opentype");;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextIt.otf') format("opentype");;
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextThin.otf') format("opentype");;
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextThIt.otf') format("opentype");;
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextExtraLight.otf') format("opentype");;
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextXLtIt.otf') format("opentype");;
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextLight.otf') format("opentype");;
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextLtIt.otf') format("opentype");;
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextMedium.otf') format("opentype");;
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextMdIt.otf') format("opentype");;
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextBold.otf') format("opentype");;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextBdIt.otf') format("opentype");;
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextExtraBold.otf') format("opentype");;
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextXBdIt.otf') format("opentype");;
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextBlack.otf') format("opentype");;
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNowText';
  src: url('./assets/fonts/HelveticaNow/HelveticaNowTextBlkIt.otf') format("opentype");;
  font-weight: 800;
  font-style: italic;
}
